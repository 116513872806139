import Contact from "./Contact";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import React, { useEffect } from "react";
import ScrollReveal from "scrollreveal";
import { Helmet } from "react-helmet";

export default function Service() {
  useEffect(() => {
    ScrollReveal().reveal(".reveal__bottom", {
      origin: "bottom",
      distance: "100px",
      duration: 1000,
      reset: false,
      easing: "ease-in-out",
    });

    ScrollReveal().reveal(".reveal__top", {
      origin: "top",
      distance: "100px",
      duration: 1000,
      reset: false,
      easing: "ease-in-out",
    });

    ScrollReveal().reveal(".reveal__left", {
      origin: "left",
      distance: "100px",
      duration: 1000,
      reset: false,
      easing: "ease-in-out",
    });

    ScrollReveal().reveal(".reveal__right", {
      origin: "right",
      distance: "100px",
      duration: 1000,
      reset: false,
      easing: "ease-in-out",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Our Services | Quinn Daisies Logistics - Comprehensive Shipping Solutions
        </title>
        <meta
          name="description"
          content="Discover our comprehensive logistics services including international shipping, packaging, customs clearance, and importation services. Expert consultation for all your shipping needs."
        />
        <link rel="canonical" href="https://logistics.quinndaisies.com/services" />
        <link rel="og:canonical" href="https://logistics.quinndaisies.com/services" />
        <meta
          property="og:title"
          content="Our Services | Quinn Daisies Logistics - Comprehensive Shipping Solutions"
        />
        <meta
          property="og:description"
          content="Professional logistics services including international shipping to 150+ countries, expert packaging, customs clearance, and importation services in Nigeria."
        />
        <meta property="og:url" content="https://logistics.quinndaisies.com/services" />
        <meta
          name="keywords"
          content="logistics services, shipping consultation, packaging services, international shipping, customs clearance, importation services, Nigeria logistics"
        />
        <meta
          name="og:keywords"
          content="logistics services, international shipping, customs clearance, Nigeria logistics"
        />
      </Helmet>

      <Navbar />

      <div className="service-container">
        <img
          className="service-image"
          src="https://res.cloudinary.com/quinn-daisies-platform/image/upload/v1729770579/Quinn%20Daisies%20Logistics/logistics-means-transport-together-with-technological-futuristic-holograms_4_r2xyvl.jpg"
          alt="Quinn Daisies Logo"
        />

        <div className="service-content">
          <div className="service-content-container">
            <span className="service-content-span">
              Quinn Daisies Logistics
            </span>
            <h2>Quinn Daisies Comprehensive Logistics Solutions</h2>
            <p>
              Quinn Daisies offers tailored logistics solutions designed to
              streamline operations and enhance efficiency for businesses across
              diverse industries. Our team specializes in providing seamless,
              end-to-end logistics management that prioritizes reliability,
              speed, and cost-effectiveness, ensuring your goods reach their
              destination safely and on schedule.
            </p>
            <a href="#contact" className="home-content-button">
              <span className="home-content-button-span">
                Request a Personalized Quote
              </span>
              <img
                className="home-content-button-image"
                src="https://res.cloudinary.com/quinn-daisies-platform/image/upload/v1729712988/Quinn%20Daisies%20Logistics/Near_Me_htd7ob.png"
                alt="Arrow Right"
              />
            </a>
          </div>

          <div className="service-content-container-footer">
            <div className="service-content-container-footer-item">
              <span>Cost-Effective Logistics Solutions</span>
              <img
                src="https://res.cloudinary.com/quinn-daisies-platform/image/upload/v1730140782/Quinn%20Daisies%20Logistics/Container_Truck_kg7d3r.png"
                alt="Quinn Daisies Logistics"
              />
            </div>

            <div className="service-content-container-footer-item">
              <span>Streamlined and Efficient Operations</span>
              <img
                src="https://res.cloudinary.com/quinn-daisies-platform/image/upload/v1730140770/Quinn%20Daisies%20Logistics/Semi_Truck_Side_View_euuwqt.png"
                alt="Quinn Daisies Logistics"
              />
            </div>

            <div className="service-content-container-footer-item">
              <span>Industry-Leading Logistics Services</span>
              <img
                src="https://res.cloudinary.com/quinn-daisies-platform/image/upload/v1730140782/Quinn%20Daisies%20Logistics/Container_Truck_kg7d3r.png"
                alt="Quinn Daisies Logistics"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="home-services">
        <div className="home-services-box">
          <h2>
            Our <br /> Services
          </h2>
        </div>

        <div className="home-services-box">
          <img
            className="home-services-box-image"
            src="https://res.cloudinary.com/quinn-daisies-platform/image/upload/v1729772011/Quinn%20Daisies%20Logistics/19964825_6184545_vr5mjg.jpg"
            alt="Shipping Consultation"
          />

          <div className="home-services-box-content">
            <h2>Shipping Consultation</h2>
            <p>
              Our expert team helps you navigate complex shipping decisions,
              providing insights on the best methods, carriers, and
              transportation modes to meet your specific needs, saving you both
              time and costs.
            </p>
          </div>
        </div>

        <div className="home-services-box">
          <img
            className="home-services-box-image"
            src="https://res.cloudinary.com/quinn-daisies-platform/image/upload/v1729771779/Quinn%20Daisies%20Logistics/couriers-with-parcel-truck_1320745-36610_sxvhz5.avif"
            alt="Shipping Consultation"
          />

          <div className="home-services-box-content">
            <h2>Packaging Services</h2>
            <p>
              We use high-quality materials and best practices to carefully pack
              your goods, ensuring maximum protection and secure transport for
              both local and international deliveries.
            </p>
          </div>
        </div>

        <div className="home-services-box">
          <img
            className="home-services-box-image"
            src="https://res.cloudinary.com/quinn-daisies-platform/image/upload/v1729771779/Quinn%20Daisies%20Logistics/global-shipping-delivery-concept_1296762-8516_dhjkmr.jpg"
            alt="Shipping Consultation"
          />

          <div className="home-services-box-content">
            <h2>International Shipping</h2>
            <p>
              With partnerships across the globe, we offer international
              shipping to over 150 countries. Our team assists in selecting the
              most suitable shipping options for your requirements while
              managing customs regulations to ensure smooth transit.
            </p>
          </div>
        </div>

        <div className="home-services-box">
          <img
            className="home-services-box-image"
            src="https://res.cloudinary.com/quinn-daisies-platform/image/upload/v1729771789/Quinn%20Daisies%20Logistics/735_x3nndt.jpg"
            alt="Shipping Consultation"
          />

          <div className="home-services-box-content">
            <h2>Importation Services</h2>
            <p>
              We simplify the importation process for individuals and businesses
              by handling goods from over 33 countries into Nigeria, offering
              fast and safe transport every step of the way.
            </p>
          </div>
        </div>

        <div className="home-services-box">
          <img
            className="home-services-box-image"
            src="https://res.cloudinary.com/quinn-daisies-platform/image/upload/v1729542833/Quinn%20Daisies%20Logistics/technological-futuristic-holograms-logistics-means-transport_1_vm61wy.jpg"
            alt="Shipping Consultation"
          />

          <div className="home-services-box-content">
            <h2>Customs Clearance</h2>
            <p>
              Our experienced customs team ensures quick and efficient clearance
              for your goods, minimizing delays and avoiding complications with
              regulatory authorities, so your items reach their destination
              without hassle.
            </p>
          </div>
        </div>
      </div>

      <Contact />
      <Footer />
    </>
  );
}
